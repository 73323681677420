<template>
    <NuxtLayout :name="layout">
        <NuxtPage />
    </NuxtLayout>
</template>
<script setup lang="ts">
import constants from './config/constants'

const Route = useRoute()

const { $lang } = useNuxtApp()

const config = useRuntimeConfig()

const voidLayoutRoutes = [`open-slug`, `go-id`, `go-${$lang.routes.brands}-id`, `landing-slug`]

const layout = computed(() => {
    return voidLayoutRoutes.find((r) => r === Route.name) ? 'void' : 'default'
})

let analyticsHead = {
    script: [],
    noscript: [],
} as
    | {
          script: []
          noscript: []
      }
    | any

const allowAnalyticsCookie = useCookie(constants.cookies.cookies_bot_config.marketing)

if (config.public.variant.isBchollos || config.public.variant.isMega || config.public.variant.isJdescuentos) {
    analyticsHead = {
        script: [
            ...(!!config.public.variant.metaData.gtmId
                ? [
                      {
                          hid: 'gtag-src-head',
                          type: 'text/javascript',
                          'data-cookieconsent': 'statistics',
                          async: true,
                          defer: true,
                          src:
                              'https://www.googletagmanager.com/gtag/js?id=' +
                              config.public.variant.metaData.gtmId,
                      },
                      {
                          hid: 'gtag-head',
                          type: 'text/javascript',
                          'data-cookieconsent': 'statistics',
                          innerHTML: `
                            window.dataLayer = window.dataLayer || [];
                            function gtag(){dataLayer.push(arguments);}
                            gtag('js', new Date());
                            gtag('config', '${config.public.variant.metaData.gtmId}');
                            `,
                      },
                  ]
                : []),

            ...(!!config.public.variant.metaData.GTMKey
                ? [
                      {
                          key: 'gtm-head',
                          type: 'text/javascript',
                          innerHTML: `setTimeout(function(){
                      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.type='text/javascript';j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${config.public.variant.metaData.GTMKey}');
                  }, 6000)`,
                      },
                  ]
                : []),
            ...(!!config.public.variant.metaData.digidipKey
                ? [
                      {
                          key: 'digidip-head',
                          type: 'text/javascript',
                          innerHTML: `(function(){
                      var loc = window.location.href;
                      var dd = document.createElement('script');
                      dd.type = 'text/javascript'; dd.src = 'https://static.digidip.net/${config.public.variant.metaData.digidipKey}.js?loc=' + loc;
                      var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(dd, s);
                    })()`,
                      },
                  ]
                : []),
        ],
        noscript: !!config.public.variant.metaData.GTMKey
            ? [
                  {
                      hid: 'gtm-body',
                      innerHTML: `<iframe src="https://www.googletagmanager.com/ns.html?id=${config.public.variant.metaData.GTMKey}" height="0" width="0" style="display: none; visibility: hidden"></iframe>`,
                      body: true,
                  },
              ]
            : [],
    }
}

useHead({
    htmlAttrs: {
        lang: config.public.variant.metaData.siteLangCode.code,
    },
    script: [
        {
            defer: true,
            src: '/js/sienna.js',
        },
        {
            key: 'fa-head',
            type: 'text/javascript',
            async: true,
            defer: true,
            innerHTML: `setTimeout(function() {
      var script = document.createElement("script")
      script.type = "text/javascript"
      script.src = "/js/fontawesome.js"
      script.async = true
      script.defer = true
      document.head.append(script)
                  }, 6000)`,
        },
        {
            src: 'https://analytics.ahrefs.com/analytics.js',
            'data-key': config.public.variant.metaData.ahref,
            defer: true,
        },
        ...(config.public.variant.isBchollos && config.public.variant.metaData.cookieBot
            ? [
                  {
                      src:
                          'https://consent.cookiebot.com/uc.js?cbid=' +
                          config.public.variant.metaData.cookieBot,
                      type: 'text/javascript',
                  },
              ]
            : []),
    ],
    link: [
        {
            rel: 'icon',
            href: config.public.variant.metaData.siteFavicon,
        },
    ],
})

useSeoMeta(config.public.variant.metaData.defaultOGTags as any)
</script>
