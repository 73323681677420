
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as masked_45redirectjs_45EmqNduYhnJ0whZEqgPM13uS0VKQBn4zO8tNdr828Meta } from "~/views/masked-redirect.vue?macro=true";
import { default as store_45landingjeim_5F0YlxhCKgZYsTIfDlHbA33w_45f0Nku1k9pvmo8Meta } from "~/views/store-landing.vue?macro=true";
import { default as enviar_45cuponQ4K_p_mTi2gnPq6rac267vtOS5GeFrJGcYt1dYt39CwMeta } from "~/views/enviar-cupon.vue?macro=true";
import { default as homepage_45new_45design3ba7YiBKVwVOBSLBPkHgxEKzdX5eYtAxxCmhtZhuB9cMeta } from "~/views/homepage-new-design.vue?macro=true";
import { default as store_45bridgeR4JytVe5GbI1Uk3qu_8kiXQI2Nm7AvRnEIsQLmtF4owMeta } from "~/views/store-bridge.vue?macro=true";
import { default as acerca_45de_45bchollosjqpMiBJ6Lo294dWS8qv1XXA2QMmsjKF3TZu3ZE2LWxMMeta } from "~/views/acerca-de-bchollos.vue?macro=true";
import { default as aviso_45legalzZTCv0gPXuZTxdri4sciKV_45Z11JYjtLHKttOLzUEQFsMeta } from "~/views/aviso-legal.vue?macro=true";
import { default as ayuda988E8VnK9eTADw59yiPhqUxSh0VDFyGhRiJ7loiGkNwMeta } from "~/views/ayuda.vue?macro=true";
import { default as indexhmwhJlO814sx4bL3aXBXH3ZwUrh9weCgj_dZ_45ZBpRR4Meta } from "~/views/blog/index.vue?macro=true";
import { default as contactoJOVm7AMpq76R36YR0B81XezhwhImc69fOZq1x5oqpRYMeta } from "~/views/contacto.vue?macro=true";
import { default as cookiesbVD_updlAiBlXeIhfB8JnMrY9ZEqznQrW4quwKkURnEMeta } from "~/views/cookies.vue?macro=true";
import { default as cuponescdxkz5SNPvjSWIsRAm4_455Ai_Z511oGfbr9jXpA4QYlQMeta } from "~/views/cupones.vue?macro=true";
import { default as index3yJQ2XN1zIOoj6_45dYyOsK0oxgyoiRur1eRvn0fKMw1wMeta } from "~/views/eventos/index.vue?macro=true";
import { default as expiradosMyYUuY9ziISm7WjeC1Cq2eO_45Nw_L4t_455eRSjBLl7hEoMeta } from "~/views/expirados.vue?macro=true";
import { default as faqy_Eec5_450TrkoTAKzArwzVEA1A72r4wY9YiKS2In0LwUMeta } from "~/views/faq.vue?macro=true";
import { default as gratisNIDGu1gdXAGvZKszHt5iarVTc_X_45n9HHZ9nJ5abAdGsMeta } from "~/views/gratis.vue?macro=true";
import { default as ofertasfocBZC3d8fAcSlt4c9yruD1Ea9t3gkSXnxOrJIZ4YdoMeta } from "~/views/ofertas.vue?macro=true";
import { default as politicas_45de_45privacidadZSiETKAuifXUvA0wN6aiOH_m19sAd725xXzM0Zv3n6AMeta } from "~/views/politicas-de-privacidad.vue?macro=true";
import { default as prensaBsrzlURGZmxTBQfcxaTTENnfp086PIZDb_H5GZkiUD8Meta } from "~/views/prensa.vue?macro=true";
import { default as reglas_45de_45comunidadO7kZbn6E5EeyGNhOrHsyUzwcaGt2ZpHL_45fslAKWXM0UMeta } from "~/views/reglas-de-comunidad.vue?macro=true";
import { default as searchE196Ut0wBPwkvUp5XnnjhRjrez7n2Jdh1aYSI6aOzeAMeta } from "~/views/search.vue?macro=true";
import { default as _91slug_93jkuWmiogGHCjMBqkoJ7eXBgD9lqX1wtO1J7nel8PZ9YMeta } from "~/views/eventos/[slug].vue?macro=true";
import { default as indexEHT_45C5Qi2WlN22xLbHdW2fBwqmx1pzu5tk_JZvxVFFIMeta } from "~/views/blog/[category]/index.vue?macro=true";
import { default as _91article_93a_45_4570Oy1EjdCEMOOiEv_J6CLCodCBCaEM3EHvoeBeg0Meta } from "~/views/blog/[category]/[article].vue?macro=true";
import { default as top25auf3gRoElujA852X5O0i7Myv9NDbGLS0pEFdQwo14XQMeta } from "~/views/top25.vue?macro=true";
import { default as _91slug_93_458PhMJTXotDlaaBqJ6cN4vUkEAyZcKlGpyr33J30J6cMeta } from "~/views/autores/[slug].vue?macro=true";
import { default as component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxUMeta } from "/home/cleavr/www.bchollos.es/releases/20250409063149925/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxU } from "/home/cleavr/www.bchollos.es/releases/20250409063149925/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "go-id",
    path: "/go/:id",
    component: () => import("~/views/masked-redirect.vue")
  },
  {
    name: "landing-slug",
    path: "/landing/:slug",
    component: () => import("~/views/store-landing.vue")
  },
  {
    name: enviar_45cuponQ4K_p_mTi2gnPq6rac267vtOS5GeFrJGcYt1dYt39CwMeta?.name,
    path: "/enviar-cupon",
    component: () => import("~/views/enviar-cupon.vue")
  },
  {
    name: "homepage",
    path: "/",
    component: () => import("~/views/homepage-new-design.vue")
  },
  {
    name: "codigos-descuento",
    path: "/codigos-descuento",
    component: () => import("~/views/store-bridge.vue")
  },
  {
    name: "codigos-descuento-slug",
    path: "/codigos-descuento/:slug",
    component: () => import("~/views/store-bridge.vue")
  },
  {
    name: acerca_45de_45bchollosjqpMiBJ6Lo294dWS8qv1XXA2QMmsjKF3TZu3ZE2LWxMMeta?.name,
    path: "/acerca-de-nosotros",
    component: () => import("~/views/acerca-de-bchollos.vue")
  },
  {
    name: aviso_45legalzZTCv0gPXuZTxdri4sciKV_45Z11JYjtLHKttOLzUEQFsMeta?.name,
    path: "/aviso-legal",
    component: () => import("~/views/aviso-legal.vue")
  },
  {
    name: ayuda988E8VnK9eTADw59yiPhqUxSh0VDFyGhRiJ7loiGkNwMeta?.name,
    path: "/ayuda",
    component: () => import("~/views/ayuda.vue")
  },
  {
    name: indexhmwhJlO814sx4bL3aXBXH3ZwUrh9weCgj_dZ_45ZBpRR4Meta?.name,
    path: "/blog",
    component: () => import("~/views/blog/index.vue")
  },
  {
    name: contactoJOVm7AMpq76R36YR0B81XezhwhImc69fOZq1x5oqpRYMeta?.name,
    path: "/contacto",
    component: () => import("~/views/contacto.vue")
  },
  {
    name: cookiesbVD_updlAiBlXeIhfB8JnMrY9ZEqznQrW4quwKkURnEMeta?.name,
    path: "/cookies",
    component: () => import("~/views/cookies.vue")
  },
  {
    name: cuponescdxkz5SNPvjSWIsRAm4_455Ai_Z511oGfbr9jXpA4QYlQMeta?.name,
    path: "/cupones",
    component: () => import("~/views/cupones.vue")
  },
  {
    name: index3yJQ2XN1zIOoj6_45dYyOsK0oxgyoiRur1eRvn0fKMw1wMeta?.name,
    path: "/eventos",
    component: () => import("~/views/eventos/index.vue")
  },
  {
    name: expiradosMyYUuY9ziISm7WjeC1Cq2eO_45Nw_L4t_455eRSjBLl7hEoMeta?.name,
    path: "/expirados",
    component: () => import("~/views/expirados.vue")
  },
  {
    name: faqy_Eec5_450TrkoTAKzArwzVEA1A72r4wY9YiKS2In0LwUMeta?.name,
    path: "/faq",
    component: () => import("~/views/faq.vue")
  },
  {
    name: gratisNIDGu1gdXAGvZKszHt5iarVTc_X_45n9HHZ9nJ5abAdGsMeta?.name,
    path: "/gratis",
    component: () => import("~/views/gratis.vue")
  },
  {
    name: store_45bridgeR4JytVe5GbI1Uk3qu_8kiXQI2Nm7AvRnEIsQLmtF4owMeta?.name,
    path: "/codigos-descuento",
    component: () => import("~/views/store-bridge.vue")
  },
  {
    name: ofertasfocBZC3d8fAcSlt4c9yruD1Ea9t3gkSXnxOrJIZ4YdoMeta?.name,
    path: "/chollo",
    component: () => import("~/views/ofertas.vue")
  },
  {
    name: politicas_45de_45privacidadZSiETKAuifXUvA0wN6aiOH_m19sAd725xXzM0Zv3n6AMeta?.name,
    path: "/politica-de-privacidad",
    component: () => import("~/views/politicas-de-privacidad.vue")
  },
  {
    name: prensaBsrzlURGZmxTBQfcxaTTENnfp086PIZDb_H5GZkiUD8Meta?.name,
    path: "/media",
    component: () => import("~/views/prensa.vue")
  },
  {
    name: reglas_45de_45comunidadO7kZbn6E5EeyGNhOrHsyUzwcaGt2ZpHL_45fslAKWXM0UMeta?.name,
    path: "/reglas",
    component: () => import("~/views/reglas-de-comunidad.vue")
  },
  {
    name: searchE196Ut0wBPwkvUp5XnnjhRjrez7n2Jdh1aYSI6aOzeAMeta?.name,
    path: "/search",
    component: () => import("~/views/search.vue")
  },
  {
    name: _91slug_93jkuWmiogGHCjMBqkoJ7eXBgD9lqX1wtO1J7nel8PZ9YMeta?.name,
    path: "/eventos/:slug",
    component: () => import("~/views/eventos/[slug].vue")
  },
  {
    name: indexEHT_45C5Qi2WlN22xLbHdW2fBwqmx1pzu5tk_JZvxVFFIMeta?.name,
    path: "/blog/:category",
    component: () => import("~/views/blog/[category]/index.vue")
  },
  {
    name: _91article_93a_45_4570Oy1EjdCEMOOiEv_J6CLCodCBCaEM3EHvoeBeg0Meta?.name,
    path: "/blog/:category/:article",
    component: () => import("~/views/blog/[category]/[article].vue")
  },
  {
    name: top25auf3gRoElujA852X5O0i7Myv9NDbGLS0pEFdQwo14XQMeta?.name,
    path: "/top-25-descuentos",
    component: () => import("~/views/top25.vue")
  },
  {
    name: _91slug_93_458PhMJTXotDlaaBqJ6cN4vUkEAyZcKlGpyr33J30J6cMeta?.name,
    path: "/autores/:slug",
    component: () => import("~/views/autores/[slug].vue")
  },
  {
    name: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxUMeta?.name,
    path: "/codigos-descuento/a",
    component: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxU
  },
  {
    name: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxUMeta?.name,
    path: "/codigos-descuento/b",
    component: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxU
  },
  {
    name: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxUMeta?.name,
    path: "/codigos-descuento/c",
    component: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxU
  },
  {
    name: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxUMeta?.name,
    path: "/codigos-descuento/d",
    component: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxU
  },
  {
    name: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxUMeta?.name,
    path: "/codigos-descuento/e",
    component: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxU
  },
  {
    name: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxUMeta?.name,
    path: "/codigos-descuento/f",
    component: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxU
  },
  {
    name: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxUMeta?.name,
    path: "/codigos-descuento/g",
    component: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxU
  },
  {
    name: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxUMeta?.name,
    path: "/codigos-descuento/h",
    component: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxU
  },
  {
    name: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxUMeta?.name,
    path: "/codigos-descuento/i",
    component: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxU
  },
  {
    name: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxUMeta?.name,
    path: "/codigos-descuento/j",
    component: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxU
  },
  {
    name: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxUMeta?.name,
    path: "/codigos-descuento/k",
    component: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxU
  },
  {
    name: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxUMeta?.name,
    path: "/codigos-descuento/l",
    component: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxU
  },
  {
    name: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxUMeta?.name,
    path: "/codigos-descuento/m",
    component: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxU
  },
  {
    name: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxUMeta?.name,
    path: "/codigos-descuento/n",
    component: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxU
  },
  {
    name: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxUMeta?.name,
    path: "/codigos-descuento/o",
    component: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxU
  },
  {
    name: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxUMeta?.name,
    path: "/codigos-descuento/p",
    component: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxU
  },
  {
    name: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxUMeta?.name,
    path: "/codigos-descuento/q",
    component: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxU
  },
  {
    name: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxUMeta?.name,
    path: "/codigos-descuento/r",
    component: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxU
  },
  {
    name: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxUMeta?.name,
    path: "/codigos-descuento/s",
    component: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxU
  },
  {
    name: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxUMeta?.name,
    path: "/codigos-descuento/t",
    component: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxU
  },
  {
    name: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxUMeta?.name,
    path: "/codigos-descuento/u",
    component: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxU
  },
  {
    name: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxUMeta?.name,
    path: "/codigos-descuento/v",
    component: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxU
  },
  {
    name: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxUMeta?.name,
    path: "/codigos-descuento/w",
    component: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxU
  },
  {
    name: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxUMeta?.name,
    path: "/codigos-descuento/x",
    component: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxU
  },
  {
    name: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxUMeta?.name,
    path: "/codigos-descuento/y",
    component: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxU
  },
  {
    name: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxUMeta?.name,
    path: "/codigos-descuento/z",
    component: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxU
  },
  {
    name: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxUMeta?.name,
    path: "/extension",
    component: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxU
  },
  {
    name: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxUMeta?.name,
    path: "/extension-desinstalada",
    component: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxU
  },
  {
    name: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxUMeta?.name,
    path: "/comentados",
    component: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxU
  },
  {
    name: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxUMeta?.name,
    path: "/novedades/:pathMatch(.*)",
    component: component_45stub1n8f406qm67e0Am6VYvcwyoEiw00rODr0QyxHqfFOxU
  }
]