import endpoints from '@/api'

export const useApiClient = () => {
    const config = useRuntimeConfig()

    const baseUrl = config.public.apiBaseUrl

    const siteOrigin = config.public.siteOrigin

    const buildHeaders = (
        bearerToken?: string | null,
        contentType?: 'application/json' | 'multipart/form-data',
    ) => {
        const headers: Record<string, string> = {}

        headers['Accept'] = 'application/json'

        if (siteOrigin) {
            headers['Origin'] = siteOrigin
        }

        if (contentType) {
            headers['Content-Type'] = contentType
        }

        if (bearerToken) {
            headers['Authorization'] = `Bearer ${bearerToken}`
        }

        return headers
    }

    return {
        buildHeaders,
        baseUrl,
        endpoints,
    }
}
