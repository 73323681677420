import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
    const router = useRouter()

    const {
        public: {
            isProduction,
            variant: {
                metaData: { sentry },
            },
        },
    } = useRuntimeConfig()

    if (isProduction && !!sentry) {
        Sentry.init({
            app: nuxtApp.vueApp,
            dsn: sentry,
            environment: 'production',
            integrations: [
                Sentry.browserTracingIntegration({ router }),
                Sentry.replayIntegration({
                    maskAllText: false,
                    blockAllMedia: false,
                }),
            ],
            ignoreErrors: [
                'TurnstileError: [Cloudflare Turnstile] Error: 300030.',
                '[Cloudflare Turnstile] Error: 300030.',
                'Page deleted (gone)',
                'Error de solicitud',
                "Can't find variable: gmo",
            ],
            tracesSampleRate: 0.2,
            tracePropagationTargets: ['localhost', origin],
            replaysSessionSampleRate: 0,
            replaysOnErrorSampleRate: 1.0,
        })
    }
})
